import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private _spinnerText: BehaviorSubject<string> = new BehaviorSubject<string>(
    'Loading...'
  );
  public spinnerText$: Observable<string> = this._spinnerText.asObservable();

  constructor(private _ngXSpinnerService: NgxSpinnerService) { }

  public show(text: string | null): void {
    if (text)
      this._spinnerText.next(text);
    this._ngXSpinnerService.show();
  }

  public hide(): void {
    this._ngXSpinnerService.hide();
  }
}
