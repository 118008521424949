import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toasts: any[] = [];

  private show(textOrTpl: string | TemplateRef<any>, options: any = {}): void {
    this.toasts.push({ textOrTpl, ...options });
  }

  public remove(toast: any): void {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  public clear(): void {
    this.toasts.splice(0, this.toasts.length);
  }

  public showError(errorMessage: string): void {
    this.show(errorMessage || '', {
      classname: 'bg-danger text-light',
      delay: 5000,
    });
  }

  public showSuccess(successMessage: string): void {
    this.show(successMessage || '', {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }

  public showWarning(warningMessage: string): void {
    this.show(warningMessage || '', {
      classname: 'bg-success text-light',
      delay: 5000,
    });
  }
}
